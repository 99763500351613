import styled from "styled-components";

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  &.voice-note-animation {
    width: 180px; /* Adjust the width as needed */
    height: 6px; /* Adjust the height as needed */
    background-color: #5f595c; /* Wave color */
    position: relative;
    overflow: hidden;
  }

  &.voice-note-animation::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      #007bff 50%,
      #007bff 100%
    );
    transform: translateX(-100%);
    animation: wave-animation 1.5s linear infinite;
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  &.paused-animation::before {
    animation-play-state: paused;
  }
`;
export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const AlertModalDialog = styled.div`
  background: ${(props) => (props.color === "green" ? "#b5f5c8" : "#fc6a68")};
  height: 42px;
  border-radius: 23px;
  text-align: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  svg {
    margin-right: 10px;
  }
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  display: ${(p) => (p.show ? "block" : "none")};
  background-color: rgba(102, 112, 133, 0.4);
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: center;
`;

export const ModalDialog = styled.div`
  position: relative;
  width: 395px !important;
  max-height: 600px !important;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15) !important;
  border-radius: 8px !important;
  margin-top: 16px !important;
  border: none !important;
  display: block;
`;

export const BodyDiv = styled.div`
  width: 379px;
  max-height: 418px;
  margin-bottom: 22px;
  background: #ffffff;
  border: 1px solid #cbd5e0;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15);
  border-radius: 8px;
  margin-top: 8px;
  margin-left: 8px;
  overflow: auto;
`;

export const RowHead = styled.div`
  font-family: "DM Sans,sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #212121;
`;
export const DataDiv = styled.div`
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  position: ${(p) => p.position && p.position};
  border-bottom: ${(p) => p.bb && p.bb};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.ml && p.ml};
  margin-top: ${(p) => p.mt && p.mt};
  margin-right: ${(p) => p.mr && p.mr};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  overflow: ${(p) => p.overflow && p.overflow};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.br && p.br};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  text-align: ${(p) => p.textAlign && p.textAlign};
  min-width: ${(p) => p.minWidth && p.minWidth};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  aspect-ratio: ${(p) => p.aspectRatio && p.aspectRatio};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  left: ${(p) => p.left && p.left};
`;

export const Text = styled.p`
  margin-bottom: 0px !important;
  align-items: center;
  letter-spacing: 0.0025em;
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  width: ${(p) => p.width && p.width};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  height: ${(p) => p.height && p.height};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  border: ${(p) => p.border && p.border};
`;

export const AvatarLetter = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;
export const Tooltip = styled.span`
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  &:hover .TooltipText {
    opacity: 1;
    visibility: visible;
  }
`;
export const TooltipText = styled.span`
  visibility: hidden;
  background-color: rgb(45, 55, 72);
  color: #fff;
  /* text-align: center; */
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 108%;
  left: -51%;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgb(45, 55, 72) transparent;
  }
`;
