import React from "react";
import moment from "moment";
import { Div, Text } from "../../../StyledComponents";
import {
  SearchProvider,
  TnumbComponent,
  Avatar,
  AvatarLetter,
  AvatarImage,
} from "../StyledComponents";
import { UserContainer, UserContent } from "./Styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUnreadCount } from "../../../StateManagement/Reducers/MessageState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const ProviderList = ({
  list,
  OnChangeSearch,
  sessioVal,
  handleClick,
  search,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  // const File_URL =
  // process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documentimg/";

  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  var File_URL = finalurl + "/sites/" + DBHost + "/documentimg/";

  const dispatch = useDispatch();

  const generalMsg = useSelector((state) => state.Revision.generalMsg);

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  // ------------------------------State And Variables End------------------------ //

  return (
    <>
      <Div
        width="37%"
        height="100%"
        padding="0px 0px 0px 0px"
        background="#ffffff"
      >
        <Div display="flex" width="100%" marginLeft="18px">
          <SearchProvider searchVal={OnChangeSearch} />
        </Div>
        <UserContainer>
          <UserContent
            active={-1 === sessioVal}
            onClick={() => {
              handleClick(-1, "fromgeneral");
            }}
            // style={{
            //   marginTop: "17px",
            // }}
            marginTop="17px"
          >
            <Avatar
              width="40px"
              height="40px"
              marginTop="-5px"
              border="1px solid #ffffff"
            >
              <AvatarLetter top="11px">G</AvatarLetter>
            </Avatar>
            <Div width="60%" marginTop="-7px" background="none">
              <Text
                color="#2E2E2E"
                mb="4px"
                fontSize="16px"
                fontWeight="700"
                textAlign="left"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                fontFamily="'Open Sans', sans-serif"
              >
                General
              </Text>
              <Div
                color="rgba(46, 46, 46, 0.50)"
                fontSize="13px"
                fontWeight="400"
                textAlign="left"
                whiteSpace="nowrap"
                width="130px"
                textOverflow="ellipsis"
                overflow="hidden"
                background="none"
                marginTop="-12px"
              >
                {generalMsg?.type === "image" ? (
                  <TnumbComponent
                    message={generalMsg?.message}
                    variant="image"
                  />
                ) : generalMsg?.type === "doc" ? (
                  <TnumbComponent message={generalMsg?.message} variant="doc" />
                ) : generalMsg?.type === "audio" ? (
                  <TnumbComponent
                    message={generalMsg?.message}
                    variant="audio"
                  />
                ) : (
                  generalMsg?.message
                )}
              </Div>
            </Div>
            <Div
              display="flex"
              alignItems="end"
              marginLeft="auto"
              background="none"
              marginBottom="18px"
              flexDirection="column"
            >
              <Text
                width="unset"
                fontWeight="400"
                fontSize="12px"
                lineHeight="12px"
                color="#7d8592"
                background="none"
              >
                {generalMsg?.date && moment(generalMsg?.date).format("hh:mm ")}
              </Text>
              {generalMsg?.unreadCount !== "0" && generalMsg !== "" && (
                <Div
                  height="18px"
                  width="18px"
                  backColor="#2C7BE5"
                  borderRadius="50%"
                  marginTop="-5px"
                  marginLeft="auto"
                  padding="3px"
                  zIndex="100"
                  // left="782px"
                >
                  <Text
                    fontFamily="Nunito Sans"
                    fontWeight="600"
                    fontSize="12px"
                    lineHeight="12px"
                    color="#ffffff"
                  >
                    {generalMsg?.unreadCount}
                  </Text>
                </Div>
              )}
            </Div>
          </UserContent>
          {list.length > 0 ? (
            list
              .filter(
                (data) =>
                  data.ProviderName?.toLowerCase().indexOf(
                    search.toLowerCase()
                  ) > -1
              )
              .map((item, index) => (
                <UserContent
                  active={item.id === sessioVal}
                  onClick={() => {
                    const data = {
                      sender_id: user_id,
                      receive_id: item.id,
                      gen: 0,
                    };
                    dispatch(getUnreadCount(data));
                    handleClick(index, item);
                  }}
                  key={item.id}
                >
                  {item.img_url === null ? (
                    <Avatar
                      width="40px"
                      height="40px"
                      marginTop="-5px"
                      border="1px solid #ffffff"
                    >
                      <AvatarLetter top="11px">
                        {item.ProviderName.charAt(0).toUpperCase()}
                      </AvatarLetter>
                    </Avatar>
                  ) : (
                    <AvatarImage
                      src={File_URL + item.img_url}
                      alt="image-failed"
                      loading="lazy"
                      width="40px"
                      height="40px"
                      border="1px solid #ffffff"
                      objectFit="cover"
                    />
                  )}

                  <Div width="60%" marginTop="-7px" background="none">
                    <Text
                      color="#2E2E2E"
                      mb="4px"
                      fontSize="16px"
                      fontWeight="700"
                      textAlign="left"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontFamily="'Open Sans', sans-serif"
                    >
                      {item.ProviderName}
                    </Text>
                    <Div
                      color="rgba(46, 46, 46, 0.50)"
                      fontSize="13px"
                      fontWeight="400"
                      textAlign="left"
                      whiteSpace="nowrap"
                      width="130px"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      marginTop="-12px"
                      background="none"
                    >
                      {item.type === "image" ? (
                        <TnumbComponent
                          message={item.message}
                          variant="image"
                        />
                      ) : item.type === "doc" ? (
                        <TnumbComponent message={item.message} variant="doc" />
                      ) : item.type === "audio" ? (
                        <TnumbComponent
                          message={item.message}
                          variant="audio"
                        />
                      ) : (
                        item.message
                      )}
                    </Div>
                  </Div>
                  <Div
                    display="flex"
                    alignItems="end"
                    marginLeft="auto"
                    background="none"
                    marginBottom="11px"
                    flexDirection="column"
                  >
                    <Text
                      width="unset"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="12px"
                      color="#7d8592"
                      background="none"
                    >
                      {item.date && moment(item.date).format("hh:mm ")}
                    </Text>
                    {item.unreadCount !== "0" && (
                      <Div
                        height="18px"
                        width="18px"
                        backColor="#B91414"
                        borderRadius="50%"
                        marginTop="-5px"
                        marginLeft="auto"
                        padding="3px"
                        zIndex="100"
                        // left="782px"
                      >
                        <Text
                          fontFamily="DM Sans,sans-serif"
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="12px"
                          color="#ffffff"
                        >
                          {item.unreadCount}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </UserContent>
              ))
          ) : (
            <Div paddingTop="170px">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="16px"
                color="#000"
              >
                No Providers to Chat
              </Text>
            </Div>
          )}
        </UserContainer>
      </Div>
    </>
  );
};

export default ProviderList;
