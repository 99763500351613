import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import AppointmentRequest from "./AppointmentRequest";
import SecureMessage from "./SecureMessage";
import Notification from "./Notification";
import axiosInstance from "../../../axios/axios";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { ModalDialog, DataDiv, Text } from "./styles";

const { TabPane } = Tabs;
const Notifications = (props) => {
  const [ModalAnimat] = useState(true);
  const [DataCount, setDataCount] = useState(props.appData);
  const [MsgCount, setMsgCount] = useState(0);
  const [count, setCount] = useState("0");
  const [actTab, setActTab] = useState("1");
  const [isAvailabilityNotify, setIsAvailabilityNotify] = useState(false);
  const group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");

  const success = (val) => {
    setDataCount(val);
  };
  const handleactive = (activeKey) => {
    setActTab(activeKey);
    if (activeKey === "2") {
      console.log(activeKey);
      setIsAvailabilityNotify(false);
      axiosInstance
        .get("/vozo/patient/notification/data?type=remove&id=0")
        .then(() => {
          props.getNotify();
        });
    }
  };

  useEffect(() => {
    setCount(props.notifyCount);
    setMsgCount(props.MsgCount);

    if (props.show === false) {
      setActTab("1");
    }
  }, [props.MsgCount, props.show, props.count]);

  return (
    <ModalDialog animation={ModalAnimat}>
      <DataDiv
        ml="188px"
        minWidth="16px"
        // width="max-content"
        // height="max-content"
        justifyContent="center"
        display="flex"
        aspectRatio="1/1"
        MaxWidth="24px"
        background="#245C66"
        br="50px"
        position="absolute"
        mt="8px"
      >
        <Text
          textAlign="center"
          fontFamily="DM Sans,sans-serif"
          fontWeight="500"
          fontSize="12px"
          color="#ffffff"
          lineHeight="15px"
          height="100%"
          display="flex"
        >
          {DataCount.length}
        </Text>
      </DataDiv>
      {(Number(group_id) === 21 || Number(group_id) === 20) && (
        <DataDiv
          ml="310px"
          minWidth="16px"
          justifyContent="center"
          alignItems="center"
          display="flex"
          aspectRatio="1/1"
          MaxWidth="24px"
          background="#245C66"
          br="50px"
          position="absolute"
          mt="8px"
        >
          <Text
            textAlign="center"
            fontFamily="DM Sans,sans-serif"
            fontWeight="500"
            fontSize="12px"
            color="#ffffff"
            lineHeight="15px"
            height="100%"
            display="flex"
          >
            {MsgCount}
          </Text>
        </DataDiv>
      )}
      <Tabs
        className="sec-msgs-left"
        activeKey={actTab}
        onChange={(activeKey) => handleactive(activeKey)}
      >
        <TabPane tab="Appointment Requests" key="1" className="appt-da">
          <AppointmentRequest
            appData={props.appData}
            profileimage={props.profileimage}
            success={success}
            successreload={props.successreload}
            refid={props.refid}
            calRefHandle={props.calRefHandle}
            show={props.show}
          />
        </TabPane>

        {(Number(group_id) === 21 || Number(group_id) === 20) && (
          <TabPane
            tab="Notifications"
            key="2"
            className="appt-dash-head tapNotifyMarBot"
          >
            <Notification
              getNotify={props.getNotify}
              dropChange={props.changeDrop}
              count={count}
              isAvailabilityNotify={isAvailabilityNotify}
              setIsAvailabilityNotify={setIsAvailabilityNotify}
            />
          </TabPane>
        )}
      </Tabs>
    </ModalDialog>
  );
};

export default Notifications;
