import React, { memo, useState } from "react";
import { Div, Text } from "../../../../StyledComponents";
import {
  Image,
  Span,
} from "../../../setting/PatientPortalSettings/StyledComponents";
import { useHistory } from "react-router-dom";
import notify_first from "../../../../assets/images/notify_first.png";
import docuIcon from "../../../../assets/images/document_notify_icon.png";
import demoIcon from "../../../../assets/images/demo_notify_icon.png";
import reportIcon from "../../../../assets/images/report_notify_icon.png";
import formIcon from "../../../../assets/images/consent_form_notify_icon.png";
import axiosInstance from "../../../../axios/axios";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../../MiddleWare/EncryptDecrypt";
import moment from "moment";
const Notification = ({
  dropChange,
  count,
  getNotify,
  isAvailabilityNotify,
  setIsAvailabilityNotify,
}) => {
  const navigate = useHistory();
  // const [isAvailabilityNotify, setIsAvailabilityNotify] = useState(false);
  console.log(isAvailabilityNotify);
  const [cardData, setCardData] = useState([]);
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  const NotificationList = [
    {
      icon: notify_first,
      title: "Availability Notification",
      count: "avail_count",
      descp: "View the status of your availability based on the appointments",
    },
    {
      icon: docuIcon,
      title: "Documents Uploaded",
      count: "doc_count",
      descp: "Review the documents uploaded by Clients in Client portal",
    },
    {
      icon: demoIcon,
      title: "Client Demographics",
      count: "demo_count",
      descp: "Clients requested to change their demographics in Client portal",
    },
    {
      icon: reportIcon,
      title: "Consent Form - Signed by Clients",
      count: "consent_count",
      descp: "Consent form which was sent for review, reviewed & signed",
    },
    {
      icon: formIcon,
      title: "Lab Reports",
      count: "report_count",
      descp: "Results for the Clients lab orders  has been reported",
    },
  ];
  const changeAvailableStatus = () => {
    axiosInstance
      .get("/vozo/changeAvailableNotifyStatus?user_Id=" + user_id)
      .then((res) => {
        let data = Decrypt_Value(res.data, "vozo");
        console.log(data);
        setCardData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const redirect = async (name) => {
    if (name === "avail_count") {
      try {
        console.log("33 redirect func befor call get notify");

        await getNotify();

        changeAvailableStatus();
        setIsAvailabilityNotify(true);
      } catch (error) {
        console.error("Error handling availability notification:", error);
      }
    } else {
      let url = name.replace("_count", "");
      if (name === "report_count") {
        navigate.push("/patient/electronicreports");
      } else {
        navigate.push("/settings/patientportal/" + url);
        window.location.reload();
      }
      dropChange();
    }
  };
  console.log(cardData, "dataa");
  return (
    <>
      <Div>
        {isAvailabilityNotify ? (
          <Div
            margin="8px"
            border="1px solid rgba(0, 0, 0, 0.15)"
            borderRadius="8px"
            boxShadow="5px 5px 8px rgba(20, 46, 110, 0.15)"
          >
            <Div
              display="flex"
              alignItems="center"
              borderRadius="8px"
              padding="12px"
              gap="8px"
            >
              <Span
                onClick={() => setIsAvailabilityNotify(false)}
                cursor="pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </Span>
              <Text
                marginBottom="0px"
                fontFamily="Open Sans"
                fontSize="14px"
                fontWeight="600"
              >
                Availability notification
              </Text>
              {/* <Span
              color="white"
              background="rgba(36, 92, 102, 1)"
              borderRadius="50%"
              width="20px"
            >
              {cardData.length}
            </Span> */}
            </Div>
            <Div
              minHeight="320px"
              maxHeight="320px"
              overflowY="scroll"
              marginBottom="6px"
            >
              {cardData.map((item, i) => {
                return (
                  <Div
                    borderBottom={
                      i != 0 ? "1px solid rgba(0, 0, 0, 0.15)" : undefined
                    }
                    border={
                      i == 0 ? "1px solid rgba(0, 0, 0, 0.15)" : undefined
                    }
                    padding="12px"
                  >
                    <Div display="flex" justifyContent="space-between">
                      {" "}
                      <Div>
                        {" "}
                        <Text
                          fontFamily="Open Sans"
                          fontSize="14px"
                          fontWeight="600"
                        >
                          {item.room_name}{" "}
                        </Text>{" "}
                      </Div>{" "}
                      <Div>
                        {" "}
                        <Text
                          color={`${
                            item.cancel_time == null
                              ? "rgba(32, 130, 65, 1)"
                              : "rgba(185, 20, 20, 1)"
                          }`}
                          fontFamily="Open Sans"
                          fontSize="14px"
                          fontWeight="400"
                        >
                          {item.cancel_time == null ? "Restored" : "Cancelled"}
                        </Text>{" "}
                      </Div>{" "}
                    </Div>
                    <Div display="flex" gap="4px" marginTop="4px">
                      {" "}
                      <Text
                        margin="0"
                        marginRight="4px"
                        fontFamily="Open Sans"
                        fontSize="14px"
                        fontWeight="400"
                        color="rgba(36, 92, 102, 1)"
                      >
                        {item.cancel_restore_date}
                      </Text>
                      {/* <Text
                        margin="0"
                        fontFamily="Open Sans"
                        fontSize="14px"
                        fontWeight="600"
                        color="rgba(36, 92, 102, 1)"
                      >
                        {item.cancel_time == null
                          ? item.restore_time
                          : item.cancel_time}
                      </Text> */}
                      {item.cancel_time == null ? (
                        <Text
                          margin="0"
                          fontFamily="Open Sans"
                          fontSize="14px"
                          fontWeight="400"
                          color="rgba(36, 92, 102, 1)"
                        >
                          {(() => {
                            const restore_time = item.restore_time;
                            const [start, end] = restore_time.split(" - ");
                            const startTime = moment(start, "HH:mm:ss");
                            const endTime = moment(end, "HH:mm:ss");
                            const diffMs = Math.abs(endTime.diff(startTime));
                            const diffMinutes = Math.floor(
                              diffMs / (1000 * 60)
                            );
                            const diffHours = Math.floor(diffMinutes / 60);
                            const remainingMinutes = diffMinutes % 60;
                            const formattedStart = startTime.format("hh:mm A");
                            const formattedEnd = endTime.format("hh:mm A");

                            if (diffHours !== 0) {
                              return `${formattedStart} - ${formattedEnd} (${diffHours}h ${remainingMinutes}m)`;
                            } else {
                              return `${formattedStart} - ${formattedEnd} (${remainingMinutes}m)`;
                            }
                          })()}
                        </Text>
                      ) : (
                        <Text
                          margin="0"
                          fontFamily="Open Sans"
                          fontSize="14px"
                          fontWeight="400"
                          color="rgba(36, 92, 102, 1)"
                        >
                          {(() => {
                            const cancel_time = item.cancel_time;
                            const [start, end] = cancel_time.split(" - ");
                            const startTime = moment(start, "HH:mm:ss");
                            const endTime = moment(end, "HH:mm:ss");
                            const diffMs = Math.abs(endTime.diff(startTime));
                            const diffMinutes = Math.floor(
                              diffMs / (1000 * 60)
                            );
                            const diffHours = Math.floor(diffMinutes / 60);
                            const remainingMinutes = diffMinutes % 60;
                            const formattedStart = startTime.format("hh:mm A");
                            const formattedEnd = endTime.format("hh:mm A");

                            if (diffHours !== 0) {
                              return `${formattedStart} - ${formattedEnd} (${diffHours}h ${remainingMinutes}m)`;
                            } else {
                              return `${formattedStart} - ${formattedEnd} (${remainingMinutes}m)`;
                            }
                          })()}
                        </Text>
                      )}
                      <Text
                        margin="0"
                        fontFamily="Open Sans"
                        fontSize="14px"
                        fontWeight="400"
                        color="rgba(36, 92, 102, 1)"
                      >
                        {item.pc_startTime}
                      </Text>{" "}
                    </Div>
                  </Div>
                );
              })}
            </Div>
          </Div>
        ) : (
          NotificationList.map((x, i) => (
            <Div
              key={i}
              display="flex"
              alignItem="center"
              padding="12px 16px"
              backcolor="rgba(64, 123, 255, 0.1)"
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
              // margin={i === 4 && "0 0 40px"}
              onClick={() => redirect(x.count)}
              cursor="pointer"
            >
              <Image height="40px" width="40px" src={x.icon} alt="icon"></Image>
              <Div paddingLeft="12px" backColor="none">
                <Text
                  color="#2E2E2E"
                  featureSettings=" 'clig' off, 'liga' off"
                  fontFamily="Open Sans"
                  fontSize="14px"
                  display="flex"
                  alignItem="center"
                  fontStyle="normal"
                  fontWeight="600"
                  padding="0 0 4px"
                  margin="0"
                >
                  {x.title}
                  {Object.entries(count).length > 0 &&
                    count[x.count] !== null && (
                      <Span
                        textAlign="center"
                        fontFamily="DM Sans,sans-serif"
                        fontWeight="500"
                        fontSize="12px"
                        color="#ffffff"
                        lineHeight="15px"
                        margin="0 0 0 6px"
                        width="16px"
                        height="16px"
                        borderRadius="50%"
                        background="#407BFF"
                        size="10px"
                        flexCenterAll
                      >
                        {count[x.count]}
                      </Span>
                    )}
                </Text>
                <Text
                  color="rgba(46, 46, 46, 0.50)"
                  fontFamily="Open Sans"
                  fontSize="12px"
                >
                  {x.descp}
                </Text>
              </Div>
            </Div>
          ))
        )}
      </Div>
    </>
  );
};

export default memo(Notification);
