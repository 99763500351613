import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Nav,
  NavItem,
  TopIcon,
  Span,
  Div,
  SubNavItem,
  UL,
  ListItem,
} from "./styles";
import { FaUsers } from "react-icons/fa";
import { BsCalendar4 } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import {
  FiFilePlus,
  FiSettings,
  FiChevronRight,
  FiChevronDown,
} from "react-icons/fi";
import PatientSubNav from "./PatientSubNav";
import ScheduleNav from "./ScheduleNav";
import BillingNav from "./BillingNav";
import ReportNav from "./ReportNav";
import SettingsNav from "./SettingsNav";
import { MdMonitor } from "react-icons/md";
import ExploreWidget from "./ExploreWidget";
import Menu from "../../assets/images/menu_icon .png";
import Close from "../../assets/images/CloseDoc.png";
import UnLoadScripts from "../popups/unLoadScript";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import ScheduleIcon from "../../assets/images/Frame_calender1.png";
import ClientIcon from "../../assets/images/Frame_client1.png";
import BillingIcon from "../../assets/images/Frame_billing1.png";
import ReportsIcon from "../../assets/images/Frame_reports1.png";
import SettingsIcon from "../../assets/images/Frame_settings1.png";
import MenuIcon from "../../assets/images/Frame_menu.png";
import CloseIcon from "../../assets/images/iconoir_cancel.png"
import ScheduleIcon1 from "../../assets/images/Frame_calender_active.png";
import ClientIcon1 from "../../assets/images/Frame_client_active.png";
import BillingIcon1 from "../../assets/images/Frame_billing_active.png";
import ReportsIcon1 from "../../assets/images/Frame_reports_active.png";
import SettingsIcon1 from "../../assets/images/Frame_settings_active.png";


const SideNav = () => {
  // ------------------------------ State and Variables start--------------------------------------------------- //
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [showPatientSubMenu, setShowPatientSubMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredSchedule, setIsHoveredSchedule] = useState(false);
  const [isHoveredClient, setIsHoveredClient] = useState(false);
  const [isHoveredBilling, setIsHoveredBilling] = useState(false);
  const [isHoveredReports, setIsHoveredReports] = useState(false);
  const [isHoveredSettings, setIsHoveredSettings] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const timeoutRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const Patient_subroutes = [
    "/patient/patientdashboard/",
    "/patient/demographics/",
    "/patient/appointment/",
    "/patient/billandinsurance/",
    "/patient/documents/",
    "/patient/problems/",
    "/patient/medication/",
    "/patient/immunization/",
    "/patient/familyhistory/",
    "/patient/procedureorder/",
    "/patient/senderx/",
    "/patient/clientportalaccess/",
  ];

  const storedNames =
    localStorage.getItem("setroles") &&
    Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  // ------------------------------ State and Variables end---------------------------------------------------//

  // ------------------------------ Functions----start------------------------------------------------------//
  const isOnPatientDashboard = Patient_subroutes.some((path) =>
    location.pathname.includes(path)
  );
  const handleMouseEnter = () => {
    setIsOpen(true);
    isOnPatientDashboard
      ? setShowPatientSubMenu(true)
      : setShowPatientSubMenu(false);
    if (
      (splitLocation[1] === "schedule" && splitLocation[2] === "nav") ||
      splitLocation[2] === "calender" ||
      (location.pathname === "/" && storedNames.appt)
    ) {
      setOpenSubMenu("schedule");
    } else {
      setOpenSubMenu(splitLocation[1]);
    }

    if (location.pathname === "/" && !storedNames.appt && storedNames.demo) {
      setOpenSubMenu("patient");
    } else if (
      location.pathname === "/" &&
      !storedNames.appt &&
      !storedNames.demo &&
      storedNames.bill
    ) {
      setOpenSubMenu("billing");
    } else if (
      location.pathname === "/" &&
      !storedNames.appt &&
      !storedNames.demo &&
      !storedNames.bill &&
      storedNames.rep
    ) {
      setOpenSubMenu("report");
    } else if (
      location.pathname === "/" &&
      !storedNames.appt &&
      !storedNames.demo &&
      !storedNames.bill &&
      !storedNames.rep &&
      storedNames.users
    ) {
      setOpenSubMenu("settings");
    }
  };

  const handleMouseLeave = () => {
    showPatientSubMenu ? setIsOpen(true) : setIsOpen(false);
    setOpenSubMenu("");
  };
  const handleClose = () => {
    isOnPatientDashboard && setShowPatientSubMenu(false);
    setIsOpen(false);
    setOpenSubMenu("");
  };

  const handleSubMenuClick = (menuName) => {
    setOpenSubMenu(openSubMenu === menuName ? "" : menuName);
  };

  const handleMouseEnterPatient = () => {
    setShowPatientSubMenu(true);
    setIsOpen(true);
    setOpenSubMenu("patient");
  };

  const handleMouseLeavePatient = () => {
    setShowPatientSubMenu(false);
    setIsOpen(false);
    setOpenSubMenu("");
  };

  const exploreMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsHovered(true);
  };

  // Timeout to work the hover effect
  const exploreMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  const handleClick = (item) => {
    setActiveItem(item);
  };
  // ------------------------------ Functions----end---------------------------------------------------//

  // ------------------------------ useEffect----start---------------------------------------------------//
  useEffect(() => {
    if (isOnPatientDashboard && isOpen) {
      handleMouseEnterPatient();
      setShowPatientSubMenu(true);
    }
  }, [isOnPatientDashboard]);
  useEffect(() => {
    UnLoadScripts();
  }, []);
  // ------------------------------ useEffect----end---------------------------------------------------//

  return (
    <>
      <Nav
        onMouseLeave={handleMouseLeave}
        isopen={isOpen ? "true" : "false"}
        isOnPatientDashboard={isOnPatientDashboard}
        zindex={isOnPatientDashboard && showPatientSubMenu ? "1052" : "11"}
      >
        {/* <Nav isOpen={isOpen} isOnPatientDashboard={isOnPatientDashboard}> */}
        <TopIcon onMouseEnter={handleMouseEnter}>
          {isOpen ? (
            <img src={CloseIcon} alt="Close-icon" style={{ width: "24px", height: "24px" }} onClick={handleClose}></img>
          ) : (
            <img src={MenuIcon} alt="menu-icon" style={{ width: "24px", height: "24px" }}></img>
          )}
        </TopIcon>
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            onMouseEnter={() => setIsHoveredSchedule(true)}
            onMouseLeave={() => setIsHoveredSchedule(false)}
            isopen={isOpen ? "true" : "false"}
            to={"/schedule/nav/1"}
            color={
              splitLocation[1] === "schedule" ||
              (location.pathname === "/" && storedNames.appt)
                ? "#245C66"
                : "#2E2E2E80"
            }
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("schedule");
              }
              handleClick("schedule");
            }
          }
          >
            {/* <BsCalendar4 strokeWidth="0.5" /> */}
            <img
              src={
              (splitLocation[1] === "schedule" || location.pathname === "/" || isHoveredSchedule || activeItem === "schedule")
                ? ScheduleIcon1  
                : ScheduleIcon 
              }
              alt="Schedule Icon"
              style={{ width: "24px", height: "24px" }}
            />
           
            <Span>Schedule</Span>
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("schedule")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "schedule" ||
                (location.pathname === "/" && storedNames.appt)
                  ? "#245C66"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "schedule" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "schedule" && <ScheduleNav />}
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            onMouseEnter={() => setIsHoveredClient(true)}
            onMouseLeave={() => setIsHoveredClient(false)}
            isopen={isOpen ? "true" : "false"}
            to={"/patient/nav/1"}
            id="pt_nav-lnk_mnu"
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("patient");
              }
              handleClick("schedule");
            }}
            color={
              splitLocation[1] === "patient" ||
              (location.pathname === "/" &&
                !storedNames.appt &&
                storedNames.demo)
                ? "#245C66"
                : "#2E2E2E80"
            }
          >
            {/* <FaUsers strokeWidth="0.5" /> */}
            <img 
            src={
              (splitLocation[1] === "patient" || location.pathname === "/" && !storedNames.appt && storedNames.demo || isHoveredClient || activeItem === "patient")
                ? ClientIcon1 
                : ClientIcon   
            }
            style={{ width: "24px", height: "24px" }} 
            alt="Client Icon"
            />

            <Span>Client</Span>
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("patient")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "patient" ||
                (location.pathname === "/" &&
                  !storedNames.appt &&
                  storedNames.demo)
                  ? "#245C66"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "patient" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "patient" && (
          <Div display="flex" width="100%" paddingLeft="0.86em">
            <UL
              textAlign="left"
              width="100%"
              listStyleType="none"
              padding="0"
              margin="0"
              position="relative"
            >
              <ListItem
                padding="8px"
                margin="8px 0 0 0"
                backgroundColor={isOnPatientDashboard && "#fff"}
              >
                <SubNavItem to="/patient/patientlist" exact>
                  Clients
                </SubNavItem>
              </ListItem>
              <ListItem padding="8px" margin="0">
                <SubNavItem to="/patient/formlibrary" exact>
                  Form Library
                </SubNavItem>
              </ListItem>
              <ListItem padding="8px" margin="0">
                <SubNavItem to="/patient/notetaking" exact>
                  Note Taking
                </SubNavItem>
              </ListItem>
              <ListItem padding="8px" margin="0">
                <SubNavItem to="/patient/formbuilder" exact>
                  Form Builder
                </SubNavItem>
              </ListItem>
              <ListItem padding="8px" margin="0">
                <SubNavItem to="/patient/electronicreports" exact>
                  Lab Reports
                </SubNavItem>
              </ListItem>
              <ListItem padding="8px" margin="0">
                <SubNavItem to="/patient/DocumentTemplates" exact>
                  Document Templates
                </SubNavItem>
              </ListItem>
              <ListItem padding="8px" margin="0">
                <SubNavItem to="/patient/formarchive" exact>
                  Form Archive
                </SubNavItem>
              </ListItem>
            </UL>
          </Div>
        )}
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            onMouseEnter={() => setIsHoveredBilling(true)}
            onMouseLeave={() => setIsHoveredBilling(false)}
            isopen={isOpen ? "true" : "false"}
            to={"/billing/nav/1"}
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("billing");
              }
              handleClick("billing");
            }}
            color={
              splitLocation[1] === "billing" ||
              (location.pathname === "/" &&
                !storedNames.appt &&
                !storedNames.demo &&
                storedNames.bill)
                ? "#245C66"
                : "#2E2E2E80"
            }
          >
            {/* <IoWalletOutline strokeWidth="0.5" /> */}
            <img 
             src={
              (splitLocation[1] === "billing" || isHoveredBilling || activeItem === "billing")
                ? BillingIcon1
                : BillingIcon
            }
            style={{ width: "24px", height: "24px" }} 
            alt="Billing Icon"/>
            <Span>Billing</Span>
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("billing")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={
                splitLocation[1] === "billing" ||
                (location.pathname === "/" &&
                  !storedNames.appt &&
                  !storedNames.demo &&
                  storedNames.bill)
                  ? "#245C66"
                  : "#2E2E2E80"
              }
            >
              {openSubMenu === "billing" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "billing" && <BillingNav />}
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            onMouseEnter={() => setIsHoveredReports(true)}
            onMouseLeave={() => setIsHoveredReports(false)}
            isopen={isOpen ? "true" : "false"}
            id="rpt_nav-lnk_anch"
            to={"/report/nav/1"}
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("report");
              }
              handleClick("report");
            }}
            color={
              splitLocation[1] === "report" ||
              (location.pathname === "/" &&
                !storedNames.appt &&
                !storedNames.demo &&
                !storedNames.bill &&
                storedNames.rep)
                ? "#245C66"
                : "#2E2E2E80"
            }
          >
            {/* <FiFilePlus strokeWidth="3" /> */}
            <img 
            src={
              (splitLocation[1] === "report" || isHoveredReports || activeItem === "report")
                ? ReportsIcon1  
                : ReportsIcon   
            }
            style={{ width: "24px", height: "24px" }} 
            alt="Reports Icon"/>
            <Span>Reports</Span>
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("report")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={splitLocation[1] === "report" ? "#245C66" : "#2E2E2E80"}
            >
              {openSubMenu === "report" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "report" && <ReportNav />}
        <Div display="flex" width="100%" marginLeft="40px">
          <NavItem
            onMouseEnter={() => setIsHoveredSettings(true)}
            onMouseLeave={() => setIsHoveredSettings(false)}
            isopen={isOpen ? "true" : "false"}
            to={"/settings/nav/1"}
            onClick={(e) => {
              if (isOpen) {
                e.preventDefault();
                handleSubMenuClick("settings");
                handleClick("settings");
              }
              
            }}
            color={splitLocation[1] === "settings" ? "#245C66" : "#2E2E2E80"}
          >
            {/* <FiSettings strokeWidth="3" /> */}
            <img 
            src={
              (splitLocation[1] === "settings" || isHoveredSettings || activeItem === "settings")
                ? SettingsIcon1
                : SettingsIcon
            } 
            style={{ width: "24px", height: "24px" }} 
            alt="Settings Icon"/>
            <Span>Settings</Span>
          </NavItem>
          {isOpen && (
            <Div
              onClick={() => handleSubMenuClick("settings")}
              cursor="pointer"
              margin="24px 36px 0px auto"
              color={splitLocation[1] === "settings" ? "#245C66" : "#2E2E2E80"}
            >
              {openSubMenu === "settings" ? (
                <FiChevronDown className="svgSize" />
              ) : (
                <FiChevronRight className="svgSize" />
              )}
            </Div>
          )}
        </Div>
        {openSubMenu === "settings" && <SettingsNav />}
        <Div marginBottom="80px"></Div>
        {/* Explore Vozo widget */}
        {/* <Div onMouseEnter={exploreMouseEnter} onMouseLeave={exploreMouseLeave}> */}
        {/* <Div onMouseEnter={exploreMouseEnter}> */}
        {/* {!isOpen && (
            <Div
              width="24px"
              height="24px"
              backgroundColor="#2C7BE5"
              borderRadius="50%"
              position="absolute"
              bottom="73px"
              left="20px"
            >
              <MdMonitor color="white" />
            </Div>
          )} */}
      </Nav>
      {isOnPatientDashboard && showPatientSubMenu && (
        <PatientSubNav
          onMouseEnter={handleMouseEnterPatient}
          onMouseLeave={handleMouseLeavePatient}
        />
      )}
    </>
  );
};

export default SideNav;
